import { APP_DOWNLOAD_URL } from "~/constants";
import { refreshAuthToken } from "~/helpers/api/auth";
import type { Token } from "~/model/common";
import { useAuthStore } from "~/store/auth";
import { isTokenExpired } from "~/utils/session";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const requiresAuth = to.meta.requiresAuth;

  if (to.path === routes.home.path) {
    return navigateTo(routes.overview.path);
  }

  if (import.meta.client) {
    const routeKeyFromName = Object.keys(routes).find(
      (key) => routes[key as RouteKey].name === to.name
    ) as RouteKey;

    const routeKey = routeKeyFromName;
    const visibleOnMobile = checkVisibleOnMobile(routeKey);

    if (!visibleOnMobile) {
      return navigateTo({
        path: routes.warning.path,
      });
    }

    const isAuthenticated = await checkAndSyncAuthState();

    const authStore = useAuthStore();
    await authStore.initialize();

    if (requiresAuth) {
      if (!isAuthenticated) {
        let redirectPath = routes.signin.path;
        if (redirectPath.endsWith("/")) {
          redirectPath = redirectPath.slice(0, -1);
        }
        return navigateTo({
          path: redirectPath,
          query: {
            returnTo: to.fullPath,
          },
        });
      }
    }
  }
});

async function checkAndSyncAuthState() {
  const jwtInLocalStorage = LocalStorageUtil.getItem(
    LocalStorageKeys.Token
  ) as Token;

  const refreshTokenInLocalStorage = LocalStorageUtil.getItem(
    LocalStorageKeys.RefreshToken
  ) as Token;

  if (!jwtInLocalStorage) {
    clearSession();
    return false;
  }

  const authStore = useAuthStore();
  const isJwtExpired = isTokenExpired(jwtInLocalStorage);

  if (!isJwtExpired) {
    authStore.syncAuthStoreAndSession(
      jwtInLocalStorage,
      refreshTokenInLocalStorage
    );
    return true;
  }

  const isRefreshExpired = isTokenExpired(refreshTokenInLocalStorage);

  if (isRefreshExpired) {
    clearSession();
    return false;
  }

  try {
    const response = await refreshAuthToken(refreshTokenInLocalStorage.token);

    const newToken: Token = {
      token: response.token,
      expires: response.tokenExpire,
    };

    const newRefreshToken: Token = {
      token: response.refreshToken,
      expires: response.refreshTokenExpire,
    };

    authStore.syncAuthStoreAndSession(newToken, newRefreshToken);

    return true;
  } catch (error) {
    console.error(error);

    clearSession();
    return false;
  }
}
function checkVisibleOnMobile(routeKey) {
  const nuxtApp = useNuxtApp();
  const isMobile = nuxtApp.$isMobile();

  const route = routes[routeKey];
  if (!route) {
    return true;
  }

  const visibleOnMobile = route.visibleOnMobile;

  return !isMobile || visibleOnMobile;
}
