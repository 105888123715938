export default defineNuxtRouteMiddleware(async (to, from) => {
  if (process.server) {
    const reqURL = useRequestURL();
    const headers = useRequestHeaders();
    const forwardedHost = headers["x-forwarded-host"] || reqURL.host;

    if (!forwardedHost.includes("getheltia.com")) {
      return;
    }

    let needsRedirect = false;
    let newHost = forwardedHost;
    let newPathname = decodeURIComponent(reqURL.pathname);

    // 1. Add www. prefix if missing
    if (!newHost.startsWith("www.")) {
      newHost = `www.${newHost}`;
      needsRedirect = true;
    }

    // 2. Convert pathname to lowercase
    // (except for reset-password, which is case-sensitive)
    if (
      !newPathname.includes("reset-password") &&
      newPathname !== newPathname.toLowerCase()
    ) {
      newPathname = newPathname.toLowerCase();
      needsRedirect = true;
    }

    // 3. Remove trailing slash
    if (newPathname.endsWith("/")) {
      newPathname = newPathname.slice(0, -1);
      needsRedirect = true;
    }

    if (needsRedirect) {
      const newURL = new URL(reqURL.href);
      newURL.host = newHost;
      newURL.pathname = newPathname;

      // If the new URL is different from the original, redirect
      if (newURL.href !== reqURL.href) {
        let finalURL = newURL.href;
        if (finalURL.endsWith("/")) {
          finalURL = finalURL.slice(0, -1);
        }
        return await navigateTo(finalURL, {
          redirectCode: 301,
          external: true,
        });
      }
    }
  }
});
