import { axiosInstance } from "~/plugins/axios";
import type { User } from "~/model/user";
import ApiUtils from "~/utils/api";

const apiPathPrefix = "/api/user";
export const forgotPassword = async (email: string): Promise<boolean> => {
  const queryString = ApiUtils.toQueryString({
    userEmail: email,
  });
  const { data } = await axiosInstance.get(
    `${apiPathPrefix}/forgot-password/${queryString}`
  );
  return data;
};
