import { defineStore } from "pinia";
import { Buffer } from "buffer";

import { login, logOut, refreshAuthToken } from "~/helpers/api/auth";
import Tracking from "~/utils/tracking";
import { User } from "~/model/user";
import { AuthenticationResponse } from "~/model/auth/authenticationResponse";
import { HeltiaApiError, Token } from "~/model/common";
import { setSession } from "~/utils/session";
import { forgotPassword } from "~/helpers/api/user";

declare var AppleID: any;

const usePrivateAuthStore = defineStore("privateAuth", {
  state: () => ({
    gAuthOptions: null,
    gapi: null,
    auth2: null,
    status: "initial",
    appleNonce: null,
  }),
});

export const useAuthStore = defineStore("auth", {
  state: () => ({
    form: { email: "", password: "", repassword: "" },
    forgotPasswordForm: { email: "" },
    sent: false,
    viewState: "initial",
    isLoading: false,
    authResponse: null as AuthenticationResponse | null,
    user: null as User | null,
    refreshToken: { token: null, expires: null },
    token: { token: null, expires: null },
    userAlreadyRegistered: null,
    isUserVerified: null,
    otpCode: ["", "", "", "", "", ""],
    combinedOtp: "",
    otpValidationErrors: [],
    isGoogleAuthReady: false,
    isAppleAuthReady: false,
    authErrorResponse: null as HeltiaApiError | null,
    isInitialized: false,
  }),
  getters: {
    formValidation(state): any {
      return {
        isCharLimitOk: state.form.password?.length >= 8,
        includesNumber: /\d/gm.test(state.form.password),
        isMatched: state.form.password === state.form.repassword,
        isPasswordValid: state.form.password.length > 0,
        isValid:
          state.form.password.length > 0 &&
          state.form.password === state.form.repassword,
        isEmailValid: /^[+\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(
          state.form.email
        ),
      };
    },
    forgotPasswordFormValidation(state): any {
      return {
        isEmailValid: /^[+\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(
          state.forgotPasswordForm.email
        ),
      };
    },
    emailValidationErrors(state): string[] {
      var errors = [
        state.form.email.length === 0 ? "E-posta adresi boş olamaz" : null,
        this.formValidation.isEmailValid ? null : "Geçersiz e-posta adresi",
      ];

      return errors?.filter((x) => x && x.length > 0);
    },
    forgotPasswordEmailValidationErrors(state): string[] {
      var errors = [
        state.forgotPasswordForm.email.length === 0
          ? "E-posta adresi boş olamaz"
          : null,
        this.forgotPasswordFormValidation.isEmailValid
          ? null
          : "Geçersiz e-posta adresi",
      ];

      return errors?.filter((x) => x && x.length > 0);
    },
    passwordValidationErrors(state): string[] {
      var errors = [
        state.form.password.length === 0 ? "Parola boş olamaz" : null,
      ];

      return errors?.filter((x) => x && x.length > 0);
    },
    repasswordValidationErrors(state): string[] {
      var errors = [
        state.form.repassword.length === 0 ? "Parola tekrarı boş olamaz" : null,
        this.formValidation.isMatched ? null : "Parolalar eşleşmiyor",
      ];

      return errors?.filter((x) => x && x.length > 0);
    },
    otpValidation(state) {
      return {
        isValid: state.combinedOtp.length === 6,
      };
    },
    getIsAuthenticated: (state) => {
      return state.token?.token != null;
    },
    getAuthErrorMessage: (state) => {
      if (state.authErrorResponse === null) {
        return "";
      }
      let code = state.authErrorResponse.code;
      if (code === 10) {
        return "E-posta adresinizi sistemde bulamadık";
      } else if (code === 11) {
        return "Bu e-posta adresi başka bir yöntem ile kayıtlı";
      } else if (code === 12) {
        return "E-posta adresiniz ve şifreniz uyuşmamaktadır";
      } else if (code === 13 || code === 14) {
        return "Bu e-posta adresi sistemde kayıtlı";
      } else if (code === 99) {
        return "Bu kullanıcı bu uygulamayı kullanmaya yetkili değil";
      } else {
        return "Bir hata oluştu";
      }
    },
    getIsInitialized: (state) => {
      return state.isInitialized;
    },
  },
  actions: {
    decode(plain: any) {
      return Buffer.from(plain).toString("base64");
    },
    encode(decoded: any) {
      return Buffer.from(decoded, "base64").toString();
    },
    setFormProp(prop: any) {
      this.form = { ...this.form, ...prop };
    },
    async emailSignin() {
      this.isLoading = true;
      const tracking = Tracking.getInstance();

      try {
        const response = await login(this.form.email, this.form.password);
        if (!response.sponsors || response.sponsors.length === 0) {
          this.authErrorResponse = {
            code: 99,
            message: "Şirket bulunamadı",
            origin: null,
            toJSON() {
              return {};
            },
          };

          await this.logoutApiCall();

          tracking.login("Failed");
        } else {
          this.authResponse = response;
          if (response) {
            tracking.login("Successful");
            this.setAuthTokens();
            this.userAlreadyRegistered = true;
          } else {
            tracking.login("Failed");
          }
        }
      } catch (e) {
        if (e?.response?.data?.data) {
          this.authErrorResponse = Object.assign({}, e.response.data.data);
        }
        tracking.login("Failed");
      }
      this.isLoading = false;
    },
    async authenticationResponseSignin(
      authenticationResponse: AuthenticationResponse
    ) {
      this.authResponse = authenticationResponse;

      this.setAuthTokens();
    },
    async logoutApiCall() {
      const deviceId = getDeviceId();

      try {
        await logOut(this.refreshToken.token, deviceId);
      } catch (e) {
        console.error(e);
        if (e?.response?.status === 400) {
          const refreshResponse = await refreshAuthToken(
            this.refreshToken.token
          );
          const newToken: Token = {
            token: refreshResponse.token,
            expires: refreshResponse.tokenExpire,
          };

          const newRefreshToken: Token = {
            token: refreshResponse.refreshToken,
            expires: refreshResponse.refreshTokenExpire,
          };

          this.syncAuthStoreAndSession(newToken, newRefreshToken);
          await logOut(this.refreshToken.token, deviceId);
        }
      }
    },
    async logout() {
      this.authResponse == null;
      this.user = null;
      this.token = { token: null, expires: null };
      this.refreshToken = { token: null, expires: null };
      const tracking = Tracking.getInstance();
      tracking.clearUser();
      LocalStorageUtil.clear();
      clearSession();
    },
    changeViewState(viewState: string) {
      this.viewState = viewState;
    },
    clearState() {
      this.form = { email: "", password: "", repassword: "" };
      this.forgotPasswordForm = { email: "" };
      this.authErrorResponse = null;
    },
    clearOtp() {
      this.otpCode = ["", "", "", "", "", ""];
      this.combinedOtp = "";
    },
    resetViewState() {
      this.viewState = "initial";
    },
    setAuthTokens(updateLocalStorage = true) {
      const refreshToken = new Token(
        this.authResponse.refreshToken,
        this.authResponse.refreshTokenExpire
      );

      const token = new Token(
        this.authResponse.token,
        this.authResponse.tokenExpire
      );

      this.refreshToken = refreshToken;
      this.token = token;

      setSession(token, refreshToken, updateLocalStorage);
    },
    syncAuthStoreAndSession(token: Token, refreshToken: Token) {
      this.refreshToken = refreshToken;
      this.token = token;

      setSession(token, refreshToken, true);
      this.isUserVerified = true;
    },

    isSignedIn() {
      return this.token.token !== null;
    },

    async initialize() {
      const hasToken = LocalStorageUtil.getItem(LocalStorageKeys.Token);
      if (!hasToken) {
        this.isInitialized = true;
        return;
      }

      this.isInitialized = true;
    },
    resetTemporaryData() {
      this.resetAuthErrorResponse();
      this.userAlreadyRegistered = null;
      this.clearState();
      this.clearOtp();
    },
    resetAuthErrorResponse() {
      this.authErrorResponse = null;
    },

    async onForgotPassword() {
      this.isLoading = true;
      try {
        await forgotPassword(this.forgotPasswordForm.email);
      } catch (e) {}
      this.isLoading = false;
    },
    getAccessToken() {
      return this.token?.token;
    },
  },
});
