import { default as indexyP77LXPqHpMeta } from "D:/a/r1/a/_Insights.Web/pages/genel-bakis/index.vue?macro=true";
import { default as indexggGYX8p07nMeta } from "D:/a/r1/a/_Insights.Web/pages/giris/index.vue?macro=true";
import { default as indexJ1ln9UypYpMeta } from "D:/a/r1/a/_Insights.Web/pages/index.vue?macro=true";
import { default as indexkZXhdRhb8oMeta } from "D:/a/r1/a/_Insights.Web/pages/noposter/index.vue?macro=true";
import { default as _91code_93zjYMKTsFmuMeta } from "D:/a/r1/a/_Insights.Web/pages/reset-password/[code].vue?macro=true";
import { default as indexENf8FaPgT8Meta } from "D:/a/r1/a/_Insights.Web/pages/uyari/index.vue?macro=true";
export default [
  {
    name: "genel-bakis",
    path: "/genel-bakis",
    meta: indexyP77LXPqHpMeta || {},
    component: () => import("D:/a/r1/a/_Insights.Web/pages/genel-bakis/index.vue")
  },
  {
    name: "giris",
    path: "/giris",
    meta: indexggGYX8p07nMeta || {},
    component: () => import("D:/a/r1/a/_Insights.Web/pages/giris/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("D:/a/r1/a/_Insights.Web/pages/index.vue")
  },
  {
    name: "noposter",
    path: "/noposter",
    component: () => import("D:/a/r1/a/_Insights.Web/pages/noposter/index.vue")
  },
  {
    name: "reset-password-code",
    path: "/reset-password/:code()",
    component: () => import("D:/a/r1/a/_Insights.Web/pages/reset-password/[code].vue")
  },
  {
    name: "uyari",
    path: "/uyari",
    meta: indexENf8FaPgT8Meta || {},
    component: () => import("D:/a/r1/a/_Insights.Web/pages/uyari/index.vue")
  }
]