import { Buffer } from "buffer";
import { axiosInstance } from "~/plugins/axios";
import type { AuthenticationResponse } from "~/model/auth/authenticationResponse";
import ApiUtils from "~/utils/api";

export const otpRequest = async (email) => {
  const { data } = await axiosInstance.get(`/api/auth/otp/request/${email}`);
  return data;
};

export const otpVerification = async (email, code): Promise<boolean> => {
  const verificationString = Buffer.from(email + "##" + code, "utf8").toString(
    "base64"
  );
  const queryParams = ApiUtils.toQueryString({
    verificationString: verificationString,
  });

  const { data } = await axiosInstance.post(
    `/api/auth/otp/verification/code-verification${queryParams}`,
    {}
  );
  return data;
};

export const login = async (
  email: string,
  password: string
): Promise<AuthenticationResponse> => {
  let body = {
    email: email,
    password: password,
  };
  const { data } = await axiosInstance.post("/api/auth/LogIn", body);
  return data as AuthenticationResponse;
};

export const refreshAuthToken = async (
  token: string
): Promise<AuthenticationResponse> => {
  const body = {
    token,
  };
  const { data } = await axiosInstance.post("/api/auth/RefreshToken", body);
  return data as AuthenticationResponse;
};

export const logOut = async (
  refreshToken: string,
  deviceId: string
): Promise<boolean> => {
  const body = {
    refreshToken,
    deviceId,
  };
  const { data } = await axiosInstance.post("/api/auth/LogOut", body);
  return data as boolean;
};
