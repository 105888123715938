import GoogleAnalytics from "./googleAnalytics";

type TrackingType = "mixpanel" | "googleAnalytics";

class Tracking {
  private static instance: Tracking;
  private mixpanel;

  private constructor() {
    this.mixpanel = useMixpanel();
  }

  public static getInstance(): Tracking {
    if (!Tracking.instance) {
      Tracking.instance = new Tracking();
    }

    return Tracking.instance;
  }

  private _trackEvent(
    event: string,
    params: any = null,
    trackingTypes: TrackingType[] = ["googleAnalytics", "mixpanel"]
  ) {
    // Track event on Google Analytics
    if (trackingTypes.includes("googleAnalytics")) {
      GoogleAnalytics.trackEvent(event, params);
    }

    // Track event on Mixpanel
    if (trackingTypes.includes("mixpanel") && typeof window !== "undefined") {
      this.mixpanel.track(event, params);
    }
  }

  private _timeEvent(
    event: string,
    params: any = null,
    trackingTypes: TrackingType[] = ["mixpanel"]
  ) {
    // Track event on Google Analytics
    if (trackingTypes.includes("googleAnalytics")) {
      GoogleAnalytics.trackEvent(event, params);
    }

    // Track event on Mixpanel
    if (trackingTypes.includes("mixpanel") && typeof window !== "undefined") {
      this.mixpanel.timeEvent(event, params);
    }
  }

  setUser(user: any) {
    // Set user on Google Analytics
    // GoogleAnalytics.setUser(user);

    // Set user on Mixpanel
    if (typeof window !== "undefined") {
      this.mixpanel.setUser(user);
    }
  }

  clearUser() {
    // Set user on Google Analytics
    // GoogleAnalytics.setUser(user);

    // Set user on Mixpanel
    if (typeof window !== "undefined") {
      this.mixpanel.clearUser();
    }
  }

  authentication(action: string, params: any = null) {
    this._trackEvent(`Authentication ${action}`, params);
  }

  login(action: string, params: any = null) {
    this._trackEvent(`Login ${action}`, params);
  }

  loggedOut() {
    this._trackEvent(`Logged out`);
  }
}

export default Tracking;
