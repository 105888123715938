import { defineNuxtPlugin } from "#app";
import Highcharts from "highcharts";
import HighchartsVue from "highcharts-vue";
import HighchartsMore from "highcharts/highcharts-more";
import SolidGauge from "highcharts/modules/solid-gauge";
import Accessibility from "highcharts/modules/accessibility";

// Initialize the modules
HighchartsMore(Highcharts);
SolidGauge(Highcharts);
Accessibility(Highcharts);
// Set global Highcharts options

const highchartColors = {
  neutral: {
    "10": "#d1d6d5",
    "30": "#8c9997",
    base: "#18332f",
    "40": "#657774",
    "60": "#142b27",
  },
  surface: {
    "10": "#fffdfa",
    base: "#fff6e5",
    "60": "#d5cdbf",
  },
  system: {
    error: "#D12C2C",
    alert: {
      "10": "#F8DDDD",
      base: "#D12C2C",
      "60": "#BE1E1E",
    },
    success: {
      "10": "#CAF2D2",
      base: "#41CF60",
      "60": "#2C8D41",
    },
    warning: {
      "10": "#FAEEC4",
      base: "#EDC32E",
      "60": "#A1851F",
    },
  },
  primary: {
    "10": "#CCEAE3",
    base: "#009874",
    "60": "#00674F",
  },
};

Highcharts.setOptions({
  colors: [
    highchartColors.neutral["60"], // "#142B27"
    highchartColors.primary["60"], // "#00674F"
    highchartColors.primary.base, // "#009874"
    highchartColors.neutral["40"], // "#657774"
    highchartColors.primary["10"], // "#CCEAE3"
  ],
});

const highchartStyles = {
  fonts: {
    l3: {
      fontFamily: "DM Sans",
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "14px",
      textAlign: "center",
      color: highchartColors.neutral["30"],
    },
    l2: {
      color: highchartColors.neutral["30"],
      textAlign: "center",
      fontFamily: "DM Sans",
      fontSize: "15px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "18px",
      letterSpacing: "0.64px",
    },
    b3: {
      color: highchartColors.neutral.base,
      textAlign: "center",
      fontFamily: "DM Sans",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "14px",
      letterSpacing: "0.12px",
    },
  },
  colors: highchartColors,
};

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(HighchartsVue, { highcharts: Highcharts });

  return {
    provide: {
      highcharts: Highcharts,
      highchartStyles,
    },
  };
});
